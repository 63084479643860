import { Fragment } from 'vue-frag'
const AppSettings = () => import('./app-settings/app-settings.vue')
const AccountingReport = () => import('./accounting/report.vue')
const AccountingShow = () => import('./accounting/show.vue')
const BidContainer = () => import('./bids/bid-container.vue')
const Bids = () => import('./bids/bids.vue')
const Calendar = () => import('./calendar/calendar.vue')
const CalendarResources = () =>
  import('./calendar-resources/calendar-resources.vue')
const CarePlan = () => import('./care-plans/care-plan.vue')
const CarePlans = () => import('./care-plans/care-plans.vue')
const ClassExamination = () =>
  import('./class-examination/class-examination.vue')
const ClinicalRecords = () => import('./clinical-records/clinical-records.vue')
const ClinicLogs = () => import('./clinic-logs/index.vue')
const Dashboard = () => import('./dashboard/Dashboard.vue')
const DocumentsIndex = () => import('./documents/index.vue')
const DocumentsShow = () => import('./documents/show.vue')
const DocumentTemplates = () => import('./document-templates/index.vue')
const EventTypes = () => import('./event-types/index.vue')
const ExaminationContainer = () =>
  import('./examination/examination-container.vue')
const HygieneContainer = () => import('./hygiene/hygiene-container.vue')
const DentureAppContainer = () =>
  import('./mini-app/components/denture/denture-app-container.vue')
const InvoiceDelivery = () => import('./invoice-delivery/invoice-delivery.vue')
const InvoiceForm = () => import('./invoices/form.vue')
const JournalPositions = () =>
  import('./journal-positions/journal-positions.vue')
const Login = () => import('./login/login.vue')
const MediaAssets = () => import('./media-assets/media-assets.vue')
const NewsList = () => import('./news/news-list.vue')
const OpenTasks = () => import('./open-tasks/open-tasks.vue')
const Orthodontics = () => import('./orthodontics/orthodontics.vue')
const QuickProbeContainer = () =>
  import('./quick-probe/quick-probe-container.vue')
const ParoStatusContainer = () =>
  import('./paro-status/paro-status-container.vue')
const PatientsIndex = () => import('./patients-index/patients-index.vue')
const PatientsOverview = () => import('./patients/overview.vue')
const PatientCreditCheck = () => import('./patient/credit-check.vue')
const PatientDashboard = () => import('./patient/dashboard.vue')
const QuickCommand = () => import('./quick-command/quick-command.vue')
const PatientTodos = () => import('./todos/patient-todos.vue')
const PatientDataForm = () => import('./patient-data/patient-data-form.vue')
const PatientRelationsContainer = () =>
  import('./patient-relations/patient-relations-container.vue')
const PaySlipForm = () => import('./pay-slips/form.vue')
const Prescriptions = () => import('./prescriptions/prescriptions.vue')
const SapInvoiceDashboard = () =>
  import('./dashboard/views/sap-invoice-dashboard.vue')
const SampleContainer = () => import('./samples/sample-container.vue')
const SendMedidataDialog = () =>
  import('./documents/components/send-medidata-dialog.vue')
const ServicesContainer = () => import('./services/services-container.vue')
const TailoredTemplateForm = () => import('./tailored-templates/form.vue')
const TailoredTemplates = () => import('./tailored-templates/list.vue')
const Teams = () => import('./teams/teams.vue')
const ToothAppealMiniapp = () =>
  import('./tooth-appeal/tooth-appeal-miniapp.vue')
const PatientVddsClients = () =>
  import('./patient-vdds-clients/patient-vdds-clients.vue')
const AssignEvents = () => import('./events/assign-events.vue')
const FindingReport = () => import('./finding-report/finding-report.vue')
const Practice = () => import('./practice/practice.vue')
const MessageTemplatesContainer = () =>
  import('./message-templates/message-templates-container.vue')
const PatientArchivedBanner = () =>
  import('./patient/components/archived-banner.vue')
const PatientRecalls = () => import('./patient-recalls/patient-recalls.vue')
const RemindersOverview = () => import('./reminders/overview.vue')
const SentAdditionalLettersContainer = () =>
  import('./additional-letters/sent-additional-letters-container.vue')
const SentRecalls = () => import('./recalls/sent-recalls.vue')
const IncomingPaymentsOverview = () =>
  import('./incoming-payments/overview.vue')
const InvoicesOverview = () => import('./invoices/overview.vue')
const ProfileEdit = () => import('./profile/edit.vue')
const ResetPassword = () => import('./reset-password/reset-password.vue')
const EditPassword = () => import('./reset-password/edit-password.vue')
const Unlock = () => import('./reset-password/unlock.vue')
const PatientsArchived = () =>
  import('./archived/patients-archived/patients-archived.vue')
const GuarantorsArchived = () =>
  import('./archived/guarantors-archived/guarantors-archived.vue')
const LocationsArchived = () =>
  import('./archived/locations-archived/locations-archived.vue')
const RatesSettings = () => import('./app-settings/rates/rates.vue')
const BankImport = () => import('./bank-import/bank-import.vue')
const FillingAssistantContainer = () =>
  import(
    './mini-app/components/filling-assistant/filling-assistant-container.vue'
  )
const LlmFormContainer = () =>
  import('./mini-app/components/llm-form-container.vue')
const MiniAppHeaderFillingAssistant = () =>
  import('./mini-app/components/filling-assistant/filling-assistant-header.vue')
const VddsClientsOverview = () => import('./vdds-clients/overview.vue')
const ServiceAccountsOverview = () => import('./service-accounts/overview.vue')
const FeeCatalogBrowser = () =>
  import('./fee-catalog-browser/fee-catalog-browser.vue')
const Contracts = () => import('./contracts/index.vue')
const BidOverview = () => import('./bid-overview/bid-overview.vue')
const PageWithSidepanel = () => import('./common/page-with-sidepanel.vue')
const DataExports = () => import('./data-exports/data-exports.vue')
const ReviewsIndex = () => import('./reviews/reviews-index.vue')
const PatientLatestAnamnesisCard = () =>
  import('./patient/components/latest-anamnesis-card.vue')
const PlaygroundIndex = () => import('./playground/playground-index.vue')
const LlmAssistantsIndex = () =>
  import('./llm-assistants/llm-assistants-index.vue')
const LlmAssistantsShow = () =>
  import('./llm-assistants/llm-assistants-show.vue')
const LlmFormsIndex = () => import('./llm-forms/llm-forms-index.vue')

// These components are rendered once using data-turbolinks-permanent
import Navigation from './navigation/navigation.vue'
import NavigationPatientQuickLinks from './navigation/navigation-patient-quick-links.vue'
import NavigationSearch from './navigation-search/navigation-search.vue'

export {
  Fragment,
  NavigationPatientQuickLinks,
  AppSettings,
  AccountingReport,
  AccountingShow,
  BidContainer,
  Bids,
  Calendar,
  CalendarResources,
  CarePlan,
  CarePlans,
  ClassExamination,
  ClinicalRecords,
  ClinicLogs,
  Dashboard,
  ToothAppealMiniapp,
  DocumentsIndex,
  DocumentsShow,
  DocumentTemplates,
  EventTypes,
  ExaminationContainer,
  HygieneContainer,
  DentureAppContainer,
  InvoiceDelivery,
  InvoiceForm,
  JournalPositions,
  Login,
  MediaAssets,
  NewsList,
  OpenTasks,
  Orthodontics,
  QuickProbeContainer,
  ParoStatusContainer,
  PatientsIndex,
  PatientsOverview,
  PatientCreditCheck,
  PatientDashboard,
  QuickCommand,
  PatientTodos,
  PatientDataForm,
  PatientRelationsContainer,
  PaySlipForm,
  Prescriptions,
  SapInvoiceDashboard,
  SampleContainer,
  SendMedidataDialog,
  ServicesContainer,
  TailoredTemplateForm,
  TailoredTemplates,
  Teams,
  PatientVddsClients,
  AssignEvents,
  FindingReport,
  Practice,
  MessageTemplatesContainer,
  PatientArchivedBanner,
  PatientRecalls,
  RemindersOverview,
  SentAdditionalLettersContainer,
  SentRecalls,
  NavigationSearch,
  IncomingPaymentsOverview,
  InvoicesOverview,
  ProfileEdit,
  ResetPassword,
  EditPassword,
  Unlock,
  PatientsArchived,
  GuarantorsArchived,
  LocationsArchived,
  RatesSettings,
  BankImport,
  FillingAssistantContainer,
  LlmFormContainer,
  MiniAppHeaderFillingAssistant,
  VddsClientsOverview,
  ServiceAccountsOverview,
  FeeCatalogBrowser,
  Navigation,
  Contracts,
  BidOverview,
  PageWithSidepanel,
  DataExports,
  ReviewsIndex,
  PatientLatestAnamnesisCard,
  PlaygroundIndex,
  LlmAssistantsIndex,
  LlmAssistantsShow,
  LlmFormsIndex,
}

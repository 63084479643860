<template>
  <v-app>
    <div class="full-height d-flex flex-column">
      <slot />
      <v-dialog
        v-for="(dialog, index) in dialogs"
        :key="dialog.id"
        :value="dialogVisible(index)"
        persistent
        scrollable
        :width="dialog.width"
        :fullscreen="dialog.fullscreen"
        overlay-opacity="0.86"
        :content-class="dialog.contentClass"
        @click:outside="dialog.outsideClickable && dialog.cancel()"
        @keydown.esc="dialog.cancel"
      >
        <component
          :is="dialog.component"
          v-bind="dialog.props"
          @close="dialog.close"
          @cancel="dialog.cancel"
          @closable="dialog.closable = $event"
          @outsideClickable="dialog.outsideClickable = $event"
        />
      </v-dialog>
      <template v-if="isMiniApp">
        <mini-app
          :is-open="isMiniApp"
          :title="currentMiniApp.name"
          :max-content-width="currentMiniApp.width"
          :custom-header="currentMiniApp.customHeader"
          @close="closeMiniApp"
        >
          <component
            :is="currentMiniApp.component"
            v-bind="launchedComponentProps"
          />
        </mini-app>
      </template>
      <v-snackbar
        v-model="snackbar.isOpen"
        :timeout="snackbar.timeout"
        bottom
        right
      >
        <div class="d-flex align-center justify-space-between">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <v-progress-circular
            v-if="snackbar.showUntil"
            indeterminate
            color="primary"
          />
          <span v-html="snackbar.text" />
          <span>
            <v-btn
              v-if="snackbar.primaryButton"
              dark
              text
              class="ml-6"
              @click="snackbarClick"
            >
              {{ $t(snackbar.primaryButton) }}
            </v-btn>
            <v-btn fab small dark text @click="hideSnackBar">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </span>
        </div>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import DSimpleDialog from './d-simple-dialog.vue'
import NewsDialog from '../../news/components/news-dialog.vue'
import clickOutside from '@/libs/directives/click-outside'
import miniApp from '../../mini-app/mini-app.vue'
import { apps } from '../../mini-app/apps'
import moment from 'moment'
import { mutate } from '@/libs/graphql'
import { gql } from '@apollo/client/core'
import { useNews } from '@/libs/use-news'

export default {
  directives: {
    clickOutside,
  },
  components: {
    miniApp,
  },
  provide() {
    return {
      $dialog: {
        show: (...args) => this.showDialog(...args),
        remove: (...args) => this.removeDialog(...args),
      },
      $snackbar: {
        show: (...args) => this.showSnackBar(...args),
        hide: (...args) => this.hideSnackBar(...args),
      },
      $miniApps: {
        open: (...args) => this.openMiniApp(...args),
        close: (...args) => this.closeMiniApp(...args),
      },
    }
  },
  props: {
    // Passed in via d-app tag helper
    // If "false", we can assume there is no session with the user yet.
    authenticated: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    if (props.authenticated == 'false') {
      return {
        onNewsAvailable: () => {
          // This is a no-op, since we can't show news to unauthenticated users
        },
      }
    }

    const { onNewsAvailable } = useNews({ url: '/news_items.json', limit: 3 })

    return {
      onNewsAvailable,
    }
  },
  data() {
    return {
      miniApps: apps,
      launched: null,
      launchedComponentProps: null,
      launchedCloseHandler: null,
      dialogs: [],
      snackbar: {
        isOpen: false,
        text: null,
        timeout: -1,
        clickHandler: null,
        primaryButton: null,
        promiseCallback: null,
        onCallbackFinished: null,
      },
    }
  },
  computed: {
    dialogVisible() {
      // We only show the last dialog as Vuetify does not layer them properly at the moment
      return (index) => index === this.dialogs.length - 1
    },
    currentMiniApp() {
      return this.miniApps[this.launched]
    },
    isMiniApp() {
      return Object.keys(this.miniApps).includes(this.launched)
    },
    dialogPositionClasses() {
      return {
        top: 'v-dialog--top',
        center: 'v-dialog--center',
        bottom: 'v-dialog--bottom',
      }
    },
  },
  created() {
    if (this.authenticated == 'false') {
      return
    }

    this.reportScreenStats()
  },
  mounted() {
    this.onNewsAvailable((news, trackAsSeen) => {
      this.showDialog({
        props: {
          title: news.title,
          description: news.body,
          date: news.release_on,
          url: news.url,
          imageSrc: news.image_src,
          beta: news.beta,
        },
        width: 512,
        component: NewsDialog,
      })
        .then(() => {
          // Cancelled, remember to show news again at a later point
          // Will be implemented later.
        })
        .catch(() => {
          // Closed
          trackAsSeen()
        })
    })
  },
  methods: {
    showSnackBar({
      text,
      timeout = 4000,
      clickHandler = null,
      primaryButton,
      // takes a promise and renders snackbar until Promise completes
      showUntil = null,
      // is called when showUntil completes
      onPromiseCompletes = null,
    }) {
      if (showUntil) {
        timeout = -1
      }

      // We want to show interactive snackbars longer
      if (primaryButton) {
        timeout = 6000
      }

      this.snackbar = {
        isOpen: true,
        text,
        timeout,
        clickHandler,
        primaryButton,
        showUntil,
        onPromiseCompletes,
      }

      if (showUntil) {
        showUntil
          .then(
            () => {
              this.hideSnackBar()
            },
            (reject) => {
              this.snackbar.text = reject
              this.snackbar.timeout = 5000
            }
          )
          .finally(() => {
            if (onPromiseCompletes) {
              onPromiseCompletes()
            }
          })
      }
    },
    hideSnackBar() {
      this.snackbar.isOpen = false
      this.snackbar.text = null
      this.promiseCallback = null
      this.onCallbackFinished = null
    },
    snackbarClick() {
      if (typeof this.snackbar.clickHandler === 'function') {
        this.snackbar.clickHandler()
      }
    },
    openMiniApp(appName, componentProps) {
      this.launched = appName
      this.launchedComponentProps = componentProps

      return new Promise((resolve) => {
        this.launchedCloseHandler = resolve
      })
    },
    closeMiniApp() {
      this.launched = null
      this.launchedComponentProps = null

      if (this.launchedCloseHandler) {
        this.launchedCloseHandler()
        this.launchedCloseHandler = null
      }
    },
    removeDialog(id = 0) {
      this.dialogs = this.dialogs.filter((dialog) => dialog.id !== id)
    },
    showDialog({
      component = DSimpleDialog,
      props = {},
      width = 400,
      id = this.dialogs.length,
      dialogPosition = undefined,
      allowOverflow = false,
      outsideClickable = true,
      fullscreen = false,
    }) {
      return new Promise((resolve, reject) => {
        const close = (...args) => {
          if (this.dialogs[id].closable === true) {
            this.removeDialog(id)
            resolve(...args)
          }
        }
        const cancel = (error) => {
          if (this.dialogs[id].closable === true) {
            this.removeDialog(id)
            reject(error)
          }
        }

        this.dialogs.push({
          id,
          component,
          props,
          close,
          cancel,
          width,
          fullscreen,
          closable: true,
          outsideClickable,
          contentClass: `${this.dialogPositionClasses[dialogPosition]} ${
            allowOverflow ? 'allow-overflow' : ''
          }`,
        })
      })
    },
    async reportScreenStats() {
      let statsReported = window.localStorage.getItem('screenStatsReported')
      const now = moment()

      if (
        statsReported &&
        parseInt(JSON.parse(statsReported).expiresAt) >= now.valueOf()
      ) {
        return
      }

      const response_data = await mutate({
        mutation: gql`
          mutation createScreenStat(
            $windowScreenWidth: Int!
            $windowScreenHeight: Int!
            $windowInnerHeight: Int!
            $windowInnerWidth: Int!
          ) {
            screenStatsCreate(
              windowScreenWidth: $windowScreenWidth
              windowScreenHeight: $windowScreenHeight
              windowInnerHeight: $windowInnerHeight
              windowInnerWidth: $windowInnerWidth
            ) {
              screenStat {
                id
              }
            }
          }
        `,
        variables: {
          windowScreenWidth: window.screen.width,
          windowScreenHeight: window.screen.height,
          windowInnerWidth: window.innerWidth,
          windowInnerHeight: window.innerHeight,
        },
      })

      if (!response_data.errors) {
        window.localStorage.setItem(
          'screenStatsReported',
          JSON.stringify({
            expiresAt: now.add(1, 'month').valueOf(),
          })
        )
      }
    },
  },
}
</script>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
@import '@/legacy/stylesheets/variables';
@import '@/styles/base/variables';

.vue-app {
  @import 'node_modules/vuetify/dist/vuetify.min';

  .full-height {
    height: 100%;
  }

  .v-date-picker-table .v-btn {
    padding: 0 !important;
  }

  // to fix the context menu position
  .v-application {
    position: static !important;
  }

  .v-application--wrap {
    min-height: auto;
    background-color: var(--body_background_color);
  }

  .theme--light.v-application,
  .theme--light.v-tabs > .v-tabs-bar,
  .theme--light.v-tabs-items {
    background-color: transparent;
  }

  .theme--light.application {
    background: transparent;
  }

  // set to default
  // was set in materialize-components/_form_events.scss
  input {
    height: 16px;
    margin: 0;
  }

  td input {
    font-size: 13px;
  }

  .v-card__text {
    font-size: 1rem;
  }

  input[type='text']:focus,
  input[type='password']:focus,
  input[type='email']:focus,
  input[type='url']:focus,
  input[type='date']:focus,
  input[type='tel']:focus,
  input[type='number']:focus,
  input[type='search']:focus,
  textarea:focus.materialize-textarea {
    border-bottom: none;
    box-shadow: none;
  }

  input:focus,
  textarea:focus,
  textarea.materialize-textarea:focus {
    border-color: none;
    box-shadow: none !important;
  }

  textarea.materialize-textarea:focus {
    border-color: none !important;
    border-bottom: none !important;
    box-shadow: none !important;
  }

  // Improve disabled input value readability
  .v-input--is-disabled input {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  }

  .spacer {
    width: initial;
    height: initial;
  }

  .main-title.display-1 {
    font-size: 25px !important;
    line-height: 36px;
  }

  .v-card {
    word-break: normal;
  }

  .v-sheet.v-card {
    border-radius: var(--card-border-radius);
  }

  .v-application .headline,
  .v-toolbar__title,
  .v-application .text-h5 {
    font-size: 22px !important;
    line-height: 24px;
  }

  .v-application .text-h6 {
    font-weight: 400;
  }

  .v-application .headline {
    padding: var(--card-spacing-inner) !important;
  }

  .v-card__actions {
    padding-left: var(--card-spacing-inner);
    padding-right: var(--card-spacing-inner);
  }

  .v-subheader,
  .v-list-item {
    padding: 0 var(--card-spacing-inner);
  }

  .container {
    padding: var(--card-spacing-inner);
  }

  .v-toolbar__content,
  .v-toolbar__extension {
    padding: var(--card-spacing-inner);
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    -webkit-box-shadow: var(--card-box-shadow);
    -moz-box-shadow: var(--card-box-shadow);
    box-shadow: var(--card-box-shadow);
  }

  .v-form .row--dense > .col,
  .v-form .row--dense > [class*='col-'] {
    padding-top: 0;
    padding-bottom: 0;
  }

  .v-data-footer {
    padding-left: var(--card-spacing-inner);
    padding-right: var(--card-spacing-inner);
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th:first-child,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td:first-child,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th:first-child,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td:first-child,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th:first-child {
    padding-left: var(--card-spacing-inner);
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th:last-child,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td:last-child,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th:last-child,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td:last-child,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th:last-child {
    padding-right: var(--card-spacing-inner);
  }

  // Fix for data table font size in patient-list and team-members list
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 12px;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td {
    font-size: 14px;
  }

  .v-text-field--solo > .v-input__control > .v-input__slot {
    min-height: 48px;
  }

  .v-card .v-data-table,
  .v-card .nested-table__body tr {
    background-color: transparent;
  }
}

@keyframes SelectedAnimation {
  0% {
    color: $examination-color-selected;
    fill: $examination-color-selected;
  }
  50% {
    color: lighten($examination-color-selected, 15%);
    fill: lighten($examination-color-selected, 15%);
  }
  100% {
    color: $examination-color-selected;
    fill: $examination-color-selected;
  }
}

// Dialog Style
.v-dialog {
  // Facets
  & {
    &.v-dialog--top {
      align-self: flex-start;
    }
    &.v-dialog--center {
      align-self: center;
    }
    &.v-dialog--bottom {
      align-self: flex-end;
    }
    &.allow-overflow {
      overflow-y: initial;
    }
  }
}
</style>
